import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import { Helmet } from "react-helmet";
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import QuickSignupForm from '@solid-ui-components/Educabot/QuickSignupForm'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { FormContact } from '../../../../../solid-ui-components/src/FormContact/FormContact';

const styles = {
  container: {
    margin: 'auto', 
    width: '90%',
    height: '500px',
    '@media (max-width: 657px)': {
      height: '400px',
    },
    '@media (max-width: 420px)': {
      width: '95%'
    }
  },
  contentTitle: {
    '@media (max-width: 991px)': {
      mt: 5
    }
  },
  title: {
    fontSize: '35px',
    color: '#FFFFFF',
    lineHeight: '3rem',
    '@media (max-width: 560px)': {
      fontSize: 35,
      textAlign: 'left'
    },
    '@media (max-width: 420px)': {
      fontSize: 30
    },
  },
  subtitle: {
    width: '75%',
    margin: 'auto',
    pt: '2rem',
    '@media (max-width: 560px)': {
      textAlign: 'center'
    },
  },
  icon: {
    '@media (max-width: 991px)': {
      margin: 'auto',
      textAlign: 'left'
    },
    '@media (max-width: 791px)': {
      margin: 'auto',
      width: 400,
      textAlign: 'left'
    },
  }
}

const webformData = 'https://webforms.pipedrive.com/f/bYUL6lYnmX3O4g5acovtosqvsAx9JaCsfRPAoqqZUg7tIGFUQB3W90aMOwMutaTsdl'

const HeroBlock01 = ({
  content: { text = [], images, buttons, form, icon },
  reverse
}) => (
  <Box sx={styles.container}>
    <Flex
      sx={{
        alignItems: ['center', null, null, `center`],
        flexDirection: [
          reverse ? `column` : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box
        sx={{
          margin: 'auto',
          textAlign: [`center`, null, null, `center`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <ContentText sx={styles.subtitle} content={text} />
        </Reveal>
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            {buttons && (
              <>
                <Divider space={4} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ 
              bg: '#FFFFFF', 
              width: '55%',
              margin: 'auto',
              marginTop: '1rem',
              padding: '2rem',
              borderRadius: '8px',
              '@media (max-width: 500px)': {
                width: '100%'
              },
            })}
          >
          <FormContact webformData={webformData} />
          </Reveal>
        {/* {form && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0], mt: 4 })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )} */}
      </Box>
    </Flex>
  </Box>  
)

export default WithDefaultContent(HeroBlock01)
