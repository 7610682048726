import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import Reveal from '@solid-ui-components/Reveal'
import ListItem from '@solid-ui-components/ListItem'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    flex: `1 1 0`,
    minWidth: 300,
    p: 3,
    textAlign: 'center'
  },
  itemDescription: {
    flexBasis: `3/5`,
    flexGrow: 1,
    order: [1, null, 0],
    mb: 3,
    '@media (max-width: 765px)': {
      fontSize: 18
    },
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    paddingBottom: '12px',
    textAlign: 'center',
    '@media (max-width: 765px)': {
      fontSize: 30
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    fontSize: '20px',
    width: '85%',
    margin: 'auto',
    textAlign: 'center'
  }
}

const FeaturesBlock05 = ({ content: { text, collection } }) => (
  <Container>
    <Box sx={styles.titleContent}>
      <Text sx={styles.title}> Nos encargamos de todo </Text>
      <Text sx={styles.subTitle}><strong>En Educabot te crearemos el ecosistema completo para que dictes tus cursos de manera virtual.</strong> De esta manera, vos te podrás concentrar en lo que realmente importa: enseñar a tu comunidad.</Text>
    </Box>
    {collection && (
      <>
        <Divider />
        <Flex sx={{ flexWrap: `wrap` }} m={-3}>
          {collection.map(
            ({ text, icon, collection, buttons, container }, index) => (
              <Box
                key={`item-${index}`}
                effect='fadeInLeft'
                delay={0.15 * (index + 1)}
                css={css(styles.listItem)}
              >
                <ContentContainer
                  content={container}
                  variant='cards.paper'
                  sx={{ height: `full` }}
                >
                  <Icon content={icon} mb='3' round />
                  <ContentText content={text?.[0]} />
                  <Flex sx={{ alignItems: `center`, flexWrap: `wrap` }}>
                    <ContentText
                      content={text?.slice(1)}
                      sx={styles.itemDescription}
                      mt={[3, null, 0]}
                    />
                    {collection && (
                      <Box sx={{ flexGrow: 1, mr: [3, null, 0] }}>
                        {collection.map((props, index) => (
                          <ListItem key={`item-${index}`} {...props} compact />
                        ))}
                      </Box>
                    )}
                  </Flex>
                  {buttons && (
                    <>
                      <Divider space={3} />
                      <ContentButtons content={buttons} />
                    </>
                  )}
                </ContentContainer>
              </Box>
            )
          )}
        </Flex>
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesBlock05)
