import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Icon from '@solid-ui-components/ContentIcon'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import QuickSignupForm from '@solid-ui-components/Educabot/QuickSignupForm'
import ContentButtons from '@solid-ui-components/Educabot/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

import CruzRoja from '../../../../../../../site/content/assets/flexiblocks/academia/cruz-roja.png'
import Cambridge from '../../../../../../../site/content/assets/flexiblocks/academia/cambridge.png'
import Kele from '../../../../../../../site/content/assets/flexiblocks/academia/kele.png'

const styles = {
  container: {
    margin: 'auto', 
    width: '90%',
    '@media (max-width: 657px)': {
      height: '400px',
    },
    '@media (max-width: 420px)': {
      width: '95%'
    }
  },
  contentTitle: {
    '@media (max-width: 991px)': {
      mt: 5
    }
  },
  title: {
    fontSize: '35px',
    color: '#2A205E',
    lineHeight: '3rem',
    fontWeight: 600,
    '@media (max-width: 991px)': {
      pb: '2rem'
    },
    '@media (max-width: 560px)': {
      fontSize: 35
    },
    '@media (max-width: 420px)': {
      fontSize: 30,
    },
  },
  icon: {
    '@media (max-width: 991px)': {
      margin: 'auto',
      textAlign: 'left'
    },
    '@media (max-width: 791px)': {
      margin: 'auto',
      width: 400,
      textAlign: 'left'
    },
  },
  imgContent: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1005px)': {
      flexDirection: 'column'
    },
  },
  images: {
    margin: '2rem',
    '@media (max-width: 1005px)': {
      margin: 0
    },
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, colletion },
  reverse
}) => (
  <Box sx={styles.container}>
    <Flex
      sx={{
        alignItems: ['center', null, null, `center`],
        flexDirection: [
          reverse ? `column` : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >

      <Box
        sx={{
          margin: 'auto',
          textAlign: [`center`, null, null, `center`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <Text sx={styles.title}>Ellos confían en Academia</Text>
        </Reveal>

        <Box sx={styles.imgContent}>
          <Box sx={styles.images}>
            <img src={CruzRoja} alt="cruz-roja" />
          </Box>
          <Box sx={styles.images}>
            <img src={Cambridge} alt="cambridge" />
          </Box>
          <Box sx={styles.images}>
            <img src={Kele} alt="kele" />
          </Box>
        </Box>
        <Box>

        </Box>
      </Box>
    </Flex>
  </Box>  
)


export default WithDefaultContent(HeroBlock01)
