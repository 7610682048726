import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import WhyChooseUs from '@solid-ui-blocks/Educabot/Education/Features/features'
import Contact from '@solid-ui-blocks/Educabot/Academia/Contact'
import favicon from '../../../../../../site/content/assets/favicon.png'

import HeroOne from '@solid-ui-blocks/Educabot/Academia/HeroOne'
import HeroTwo from '@solid-ui-blocks/Educabot/Academia/HeroTwo'
import ModalContactAcademia from '@solid-ui-blocks/Educabot/Academia/ModalContact'
import TrustUs from '@solid-ui-blocks/Educabot/Academia/TrustUs'
import HeroThree from '@solid-ui-blocks/Educabot/Atornillados/HeroThree'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Tabs from '@solid-ui-components/AulaMaker/Tabs'
import Screenshot from '@solid-ui-blocks/Educabot/AulaMaker/FeaturesWithPhoto/Block03'
import Features from '../../../../../solid-ui/solid-ui-blocks/src/Educabot/Academia/Features'


const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalContactAcademia content={content['contact-academia']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.heroOne}>
        <HeroOne content={content['heroOneAcademia']} reverse />
      </Box>

      <Box sx={styles.heroTwo}>
        <HeroTwo content={content['heroTwoChooseUs']} />
      </Box>

      <Box sx={styles.sectionSuite}>
        <Features content={content['suite-personalization']} />
      </Box>

      {/* <Box sx={styles.trustUs}>
        <TrustUs content={content['trust-us']} reverse/>
      </Box> */}

      <Box sx={styles.contact}>
        <Contact content={content['formContactAcademia']} reverse />
      </Box>
        <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query empresasAcademiaBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
